import Image from "next/image";
import React from "react";

import propTypes from "prop-types";

import Close from "assets/svgs/closeElement.svg";

import { motion } from "framer-motion";

export default function ElementsModal({
  element,
  texts,
  close,
  iconSvg,
  fromSnapshot,
  fromSideBar,
  useFixed,
}) {
  React.useEffect(() => {
    const handleEscape = (event) => {
      if (event.keyCode === 27) {
        close();
      }
    };

    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const removeQuotes = (str) => str.replace(/"/g, "");
  return (
    <div
      className={`inset-0 z-[130] flex h-full w-full items-center justify-center rounded-md bg-black/30 backdrop-blur-sm ${
        useFixed ? "fixed" : "absolute"
      }`}
      onClick={(e) => e.target === e.currentTarget && close()}
    >
      <motion.div
        initial={{ scale: 0.8, opacity: 0.7 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.3, opacity: 0 }}
        transition={{ duration: 0.3, ease: "easeIn" }}
        className={`relative flex flex-col gap-8 rounded-[10px] border-2 border-[#6487FF] bg-white p-8 shadow-md ${
          fromSnapshot ? "w-max max-w-[60vw]" : "w-[30vw]"
        }`}
      >
        <div className="flex justify-between">
          <div className="flex gap-4">
            <Image
              src={iconSvg}
              height="20"
              width="20"
              alt={element}
              style={{
                maxWidth: "100%",
                height: "auto",
              }}
            />
            <h2 className="text-[22px] font-semibold text-[#6487FF]">
              {element}
            </h2>
          </div>

          <div
            className="absolute right-5 top-4 cursor-pointer"
            onClick={() => close()}
          >
            <Image
              src={Close}
              height="20"
              width="20"
              alt="Close"
              style={{
                maxWidth: "100%",
                height: "auto",
              }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-6">
          {fromSideBar && (
            <h3 className="font-medium text-[#6487FF]">{fromSideBar}</h3>
          )}
          {(texts.constructor.name === "Array" ? texts : texts.split("\n")).map(
            (desc, index) => (
              <p className="m-0 p-0 text-black" key={index}>
                {removeQuotes(desc)}
              </p>
            )
          )}
        </div>
      </motion.div>
    </div>
  );
}

ElementsModal.propTypes = {
  element: propTypes.string,
  close: propTypes.func.isRequired,
  iconSvg: propTypes.string,
  texts: propTypes.string,
  fromSnapshot: propTypes.string,
  fromSideBar: propTypes.string,
  useFixed: propTypes.bool,
};
