/* eslint-disable no-underscore-dangle */
export const getUniqueBy = (arr, prop) => {
  const set = new Set();
  return arr.filter((o) => !set.has(o[prop]) && set.add(o[prop]));
};

// get sum total of field
export const getSumOf = (arr, field) => arr.reduce((a, b) => a + b[field], 0);

export const getTemplateGames = (template) => {
  // check template.elements array for games array
  // eslint-disable-next-line prefer-const
  let allGamesFromAllElements = [];
  // eslint-disable-next-line array-callback-return
  template.elements.map((element) => {
    // get individual games from element.games array and ensure they are not in allGamesFromAllElements array
    // eslint-disable-next-line array-callback-return
    element.games.map((game) => {
      if (!allGamesFromAllElements.includes(game)) {
        allGamesFromAllElements.push(game);
      }
    });
  });
  return getUniqueBy(allGamesFromAllElements, "title");
};

export const getGamesInKnacks = (knacks) => {
  // check template.elements array for games array
  // eslint-disable-next-line prefer-const
  let allGamesFromAllElements = [];
  // eslint-disable-next-line array-callback-return
  knacks.map((element) => {
    // get individual games from element.games array and ensure they are not in allGamesFromAllElements array
    // eslint-disable-next-line array-callback-return
    element?.games?.map((game) => {
      allGamesFromAllElements.push(game);
    });
  });
  return getUniqueBy(allGamesFromAllElements, "title");
};

export const getIDsFromKnacks = (newKnacks, existingIds) => {
  // get all the field _id's from the knacks array and return them as an array
  // eslint-disable-next-line prefer-const
  let knackIDs = [];
  // eslint-disable-next-line array-callback-return
  newKnacks.map((knack) => {
    // check if knack._id exists in existingIds array, if not add it to knackIDs array
    if (existingIds) {
      if (!existingIds?.includes(knack._id)) {
        knackIDs.push(knack._id);
      }
    } else {
      knackIDs.push(knack._id);
    }
  });
  return knackIDs;
};

export const getAllTemplates = (groups) => {
  // in groups array, get the templates field
  const allTemplates = [];
  // eslint-disable-next-line array-callback-return
  groups.map((grp) => {
    allTemplates.push(grp.templates);
  });
  return allTemplates;
};

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const capitilize = (value) =>
  value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

export const getscoreIdFromKnacks = (knacks) => {
  // check through knacks array and get the scoreId from each knack
  // eslint-disable-next-line prefer-const
  let scoreIds = [];
  // eslint-disable-next-line array-callback-return
  knacks.map((knack) => {
    if (knack?.scoreId) {
      scoreIds.push(knack?.scoreId);
    }
  });

  return scoreIds;
};

export const isPrimeKnack = (knackId, notLoggedIn = false) => {
  const primeKnacks = notLoggedIn
    ? [
        44, 45, 46, 50, 66, 264, 265, 266, 267, 269, 270, 274, 276, 284, 285,
        286, 287, 289, 292, 295, 296, 297, 298, 299, 302, 303, 346, 351, 353,
        354, 356, 357, 363, 364, 365, 366, 367, 368, 369, 370, 372, 375, 382,
        395, 398, 399,
      ]
    : [];

  return primeKnacks.includes(knackId);
};

function replaceAt(array, index, value) {
  const ret = array.slice(0);
  ret[index] = value;
  return ret;
}

export const rearrangeSkillGroups = (groups) => {
  /*
    Thinking Skills - Thinking
    Life Skills - Personality
    Collaboration Skills - Teamwork
    Innovation Skills - Innovation
    Leadership Skills - Leadership
    Vocational Pathways - Learning
    College Majors - Education
    Careers - Careers
  */

  let newGroups = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  // eslint-disable-next-line array-callback-return
  groups.map((group) => {
    switch (group.name) {
      case "Social Skills":
        // insert at index 0
        newGroups = replaceAt(newGroups, 0, {
          ...group,
          name: "Social Skills",
        });
        break;
      case "Thinking Skills":
        // insert at index 2
        newGroups = replaceAt(newGroups, 2, {
          ...group,
          name: "Thinking Skills",
        });
        break;
      case "Life Skills":
        // insert at index 6
        newGroups = replaceAt(newGroups, 6, { ...group, name: "Personality" });
        break;
      case "Collaboration Skills":
        // insert at index 1
        newGroups = replaceAt(newGroups, 1, {
          ...group,
          name: "Teamwork Skills",
        });
        break;
      case "Innovation Skills":
        // insert at index 5
        newGroups = replaceAt(newGroups, 5, {
          ...group,
          name: "Innovation Skills",
        });
        break;
      case "Leadership Skills":
        // insert at index 4
        newGroups = replaceAt(newGroups, 4, {
          ...group,
          name: "Leadership Skills",
        });
        break;
      case "Vocational Pathways":
        // insert at index 8
        newGroups = replaceAt(newGroups, 8, {
          ...group,
          name: "Learning Domains",
        });
        break;
      case "College Majors":
        // insert at index 7
        newGroups = replaceAt(newGroups, 7, {
          ...group,
          name: "Academic Domains",
        });
        break;
      case "Careers":
        // insert at index 9
        newGroups = replaceAt(newGroups, 9, { ...group, name: "Careers" });
        break;
      case "Innovation Economy Skills":
        // insert at index 3
        newGroups = replaceAt(newGroups, 3, {
          ...group,
          name: "Creative Skills",
        });
        break;
      default:
        break;
    }
  });
  return newGroups;
};
