import React from "react";
import propTypes from "prop-types";

export default function Loading({ text }) {
  return (
    <div className="item-spin flex flex-col gap-2">
      <div className="loader-spinner"></div>
      <span className="font-medium text-white">{text}</span>
    </div>
  );
}

Loading.propTypes = {
  text: propTypes.string,
};
