import CoinsIcon from "assets/svgs/coins.png";
import ClockIcon from "assets/svgs/clock.png";
import UnlockIcon from "assets/svgs/unlock.png";
import PlayIcon from "assets/svgs/play.png";

export const gamesList = [
  {
    id: 0,
    name: "Play Meta Maze",
    image:
      "https://res.cloudinary.com/knackapp/image/upload/c_scale,w_414/v1655989228/static/extra/jjwgllntkjwgn03v7hxt.png",
    goals: [
      {
        id: 0,
        image: PlayIcon,
        text: "Protect the water tower and defeat your enemies",
      },
      {
        id: 1,
        image: UnlockIcon,
        text: "Unlock talent badges and career maps",
      },
      {
        id: 2,
        image: ClockIcon,
        text: "10 minutes",
      },
      {
        id: 3,
        image: CoinsIcon,
        text: "Earn 100 coins when you win",
      },
    ],
  },
  {
    id: 1,
    name: "Play Bomba Blitz",
    image:
      "https://res.cloudinary.com/knackapp/image/upload/c_scale,w_414/v1655989228/static/extra/ofza3fztnvjojwkrqvvw.png",
    goals: [
      {
        id: 0,
        image: PlayIcon,
        text: "Protect the water tower and defeat your enemies",
      },
      {
        id: 1,
        image: UnlockIcon,
        text: "Unlock talent badges and career maps",
      },
      {
        id: 2,
        image: ClockIcon,
        text: "10 minutes",
      },
      {
        id: 3,
        image: CoinsIcon,
        text: "Earn 100 coins when you win",
      },
    ],
  },
  {
    id: 2,
    name: "Play Dashi Dash",
    image:
      "https://res.cloudinary.com/knackapp/image/upload/c_scale,w_414/v1655989229/static/extra/sdz4pdkuxkihjoyc0q6q.png",
    goals: [
      {
        id: 0,
        image: PlayIcon,
        text: "Protect the water tower and defeat your enemies",
      },
      {
        id: 1,
        image: UnlockIcon,
        text: "Unlock talent badges and career maps",
      },
      {
        id: 2,
        image: ClockIcon,
        text: "10 minutes",
      },
      {
        id: 3,
        image: CoinsIcon,
        text: "Earn 100 coins when you win",
      },
    ],
  },
  {
    id: 3,
    name: "Play Super Smash",
    image:
      "https://res.cloudinary.com/knackapp/image/upload/v1660132183/static/extra/uzaixk1oo1x1fdifhg8d.png",
    goals: [
      {
        id: 0,
        image: PlayIcon,
        text: "Protect the water tower and defeat your enemies",
      },
      {
        id: 1,
        image: UnlockIcon,
        text: "Unlock talent badges and career maps",
      },
      {
        id: 2,
        image: ClockIcon,
        text: "10 minutes",
      },
      {
        id: 3,
        image: CoinsIcon,
        text: "Earn 100 coins when you win",
      },
    ],
  },
];

export const apiFacingGames = [
  {
    id: 4,
    title: "Dashi Dash",
    duration: 10,
    iconUrl: "https://d3kpelgc2xgoxo.cloudfront.net/images/games/ww_app_01.png",
  },
  {
    id: 3,
    title: "Meta Maze",
    duration: 12,
    iconUrl: "https://d3kpelgc2xgoxo.cloudfront.net/images/games/mm_app_01.png",
  },
  {
    id: 5,
    title: "Bomba Blitz",
    duration: 8,
    iconUrl: "https://d3kpelgc2xgoxo.cloudfront.net/images/games/bb_app_01.png",
  },
];
